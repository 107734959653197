<template>
  <img class="unselect" @click="fbLogin" src="/static/icon/ic_facebook_circle.svg">
</template>

<script>
  export default {
    name: "BtnSigninFacebook",
    methods: {
      fbLogin() {
        const domain = location.origin.indexOf('localhost') >-1 ? 'http://localhost:8001' : 'https://master-dev.launchpack.co.kr:1111';
        const url = encodeURIComponent(`${domain}/auth/user/signin/facebook/launchpack`)
        location.href = `https://www.facebook.com/v2.10/dialog/oauth?client_id=963083811805512&redirect_uri=${url}&scope=email`
      }
    }
  }
</script>

<style lang="stylus" scoped>
  img
    width 52px
    height 52px
</style>
